<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <validation-provider
        :vid="name"
        :name="label !== null ? label : $t('time')"
        :rules="rules"
        v-slot="{ errors, valid, dirty, classes }"
      >
        <v-text-field
          v-on="on"
          :name="name"
          :value="time"
          :label="label !== null ? label : $t('time')"
          :hint="label !== null ? hint : ''"
          :error-messages="errors"
          :success="dirty && valid"
          :disabled="disabled"
          prepend-icon="mdi-clock"
          :clearable="clearable"
          :class="classes"
          readonly
          @click:clear="$emit('input', null)"
        />
      </validation-provider>
    </template>
    <v-time-picker
      v-model="time"
      @change="show = false"
      :allowed-hours="hourAllowed"
      :allowed-minutes="minuteAllowed"
      :min="min != null ? min : null"
      :max="max != null ? max : null"
      format="ampm"
      ampm-in-title
    />
  </v-menu>
</template>

<script>
import formatTime from "@/utils/mixins/formatTime";

export default {
  name: "TimePicker",
  mixins: [formatTime],
  props: {
    value: {
      type: String,
      default: null,
      validator: (prop) => typeof prop === "string" || prop == null,
    },
    name: {
      type: String,
      default: "time",
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: "",
    },
    now: {
      type: Boolean,
      default: false,
    },
    allowedHours: {
      type: Function,
      default: null,
    },
    allowedMinutes: {
      type: Function,
      default: null,
    },
    clearable: {
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      time: this.value != null ? this.value : null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        // set v-mode to now if not set
        if ((value == null || value == "") && this.now)
          this.$emit("input", this.$moment(new Date()).format("hh:mm"));
        this.time = value;
      },
    },
    time(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    hourAllowed(hour) {
      if (this.allowedHours) return this.allowedHours(hour);
      return true;
    },
    minuteAllowed(minute) {
      if (this.allowedMinutes) return this.allowedMinutes(minute);
      return true;
    },
  },
};
</script>

<i18n>
{
  "en": {
     "time": "Time"
  }, 
  "de": {
   "time": "Zeit"
  }
}
</i18n>
