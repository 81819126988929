<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("consumption") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="consumptions"
        :mobile-breakpoint="mbreakpoint"
        show-expand
        single-expand
        sort-by="name"
        :hide-default-footer="consumptions.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:item.active="{ item }">
          <boolean-value :value="item.active" />
        </template>
        <template v-slot:item.unlimited="{ item }">
          <boolean-value :value="item.unlimited" />
        </template>
        <template v-slot:item.voice_used="{ item }">
          <div v-if="item.voice_available">
            {{ formatDuration(item.voice_used || 0) }}
            {{ $t("of") }}
            {{ formatDuration(item.voice_available || 0) }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:item.sms_used="{ item }">
          <div v-if="item.sms_available">
            {{ item.sms_used || 0 }}
            {{ $t("of") }}
            {{ item.sms_available || 0 }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:item.data_used="{ item }">
          <div v-if="item.data_available">
            {{ prettyBytes(item.data_used || 0, (format = true)) }}
            {{ $t("of") }}
            {{ prettyBytes(item.data_available || 0) }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="my-4" dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th>{{ $t("name") }}</th>
                    <td>{{ item.name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("active") }}</th>
                    <td><boolean-value :value="item.active" /></td>
                  </tr>
                  <tr>
                    <th>{{ $t("unlimited") }}</th>
                    <td><boolean-value :value="item.unlimited" /></td>
                  </tr>
                  <tr>
                    <th>{{ $t("expires") }}</th>
                    <td>
                      {{
                        item.expires != null
                          ? formatDateTime(item.expires)
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("eol date") }}</th>
                    <td>
                      {{
                        item.eol_date != null
                          ? formatDateTime(item.eol_date)
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("voice_used") }}</th>
                    <td>
                      <div v-if="item.voice_available">
                        {{ formatDuration(item.voice_used || 0) }}
                        {{ $t("of") }}
                        {{ formatDuration(item.voice_available || 0) }}
                      </div>
                      <div v-else>-</div>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("sms_used") }}</th>
                    <td>
                      <div v-if="item.sms_available">
                        {{ item.sms_used || 0 }}
                        {{ $t("of") }}
                        {{ item.sms_available || 0 }}
                      </div>
                      <div v-else>-</div>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("data_used") }}</th>
                    <td>
                      <div v-if="item.data_available">
                        {{ prettyBytes(item.data_used || 0, (format = true)) }}
                        {{ $t("of") }}
                        {{ prettyBytes(item.data_available || 0) }}
                      </div>
                      <div v-else>-</div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import prettyBytes from "@/utils/mixins/prettyBytes";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDuration from "@/utils/mixins/formatDuration";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "MobilePhoneConsumption",
  components: {
    BooleanValue,
  },
  mixins: [formatDateTime, prettyBytes, formatNumber, formatDuration, isMobile],
  props: {
    consumptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("name"),
          value: "name",
        },
        {
          text: this.$i18n.t("active"),
          value: "active",
        },
        {
          text: this.$i18n.t("unlimited"),
          value: "unlimited",
        },
        {
          text: this.$i18n.t("voice_used"),
          value: "voice_used",
          align: "right",
        },
        {
          text: this.$i18n.t("sms_used"),
          value: "sms_used",
          align: "right",
        },
        {
          text: this.$i18n.t("data_used"),
          value: "data_used",
          align: "right",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
};
</script>

<i18n>
{
  "en": {
    "consumption": "Consumption",
    "active": "Active",
    "unlimited": "Unlimited",
    "name": "Name",
    "voice_used": "Voice",
    "sms_used": "SMS",
    "data_used": "Data",
    "of": "of",
    "type": "Type",
    "initial only": "Initial only",
    "recurring": "Recurring",
    "expires": "Expires",
    "eol date": "End of live date"
  },
  "de": {
    "consumption": "Verbrauch",
    "active": "Aktiv",
    "unlimited": "Unbeschränkt",
    "name": "Name",
    "voice_used": "Sprache",
    "sms_used": "SMS",
    "data_used": "Daten",
    "of": "von",
    "type": "Typ",
    "initial only": "Nur intitial",
    "recurring": "Wiederkehrend",
    "expires": "Läuft ab",
    "eol date": "ausser Betrieb"
  }
}
</i18n>
