<template>
  <v-card :loading="loading" flat :class="isMobilePhone ? '' : 'my-2'" outlined>
    <v-card-text>
      <v-expansion-panels v-model="settings" v-if="details != null">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            {{
              details.is_fax2_mail_service
                ? $t("fax to mail settings")
                : $t("common settings")
            }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <validation-observer
              ref="obs_common"
              v-slot="{ errors, invalid, validated }"
            >
              <v-row dense v-if="!isMobilePhone">
                <v-col cols="12">
                  <validation-provider
                    vid="is_fax2_mail_service"
                    :name="$t('fax to mail')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-switch
                      v-model="details.is_fax2_mail_service"
                      name="is_fax2_mail_service"
                      :label="$t('fax to mail')"
                      :hint="$t('number is fax to mail service')"
                      persistent-hint
                      :error-messages="errors"
                      :class="classes"
                      :disabled="
                        isMobilePhone || !quotasAndFunctions.has_fax2_mail
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-if="details.is_fax2_mail_service">
                <v-row dense>
                  <v-col cols="6">
                    <validation-provider
                      vid="fax2_mail_target_address"
                      :name="$t('fax to mail target address')"
                      rules="required|email"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-text-field
                        v-model="details.fax2_mail_target_address"
                        name="fax2_mail_target_address"
                        :label="$t('fax to mail target address')"
                        :hint="$t('fax to mail target address')"
                        persistent-hint
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                        single-line
                        :clearable="!isMobile"
                        :disabled="
                          isMobilePhone || !quotasAndFunctions.has_fax2_mail
                        "
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      vid="fax2_mail_password"
                      :name="$t('fax to mail password')"
                      rules="required"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-text-field
                        v-model="details.fax2_mail_password"
                        name="fax2_mail_password"
                        :label="$t('fax to mail password')"
                        :hint="$t('fax to mail password')"
                        persistent-hint
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                        single-line
                        :clearable="!isMobile"
                        :disabled="
                          isMobilePhone || !quotasAndFunctions.has_fax2_mail
                        "
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div v-else class="mt-4">
                <v-row dense>
                  <v-col cols="6">
                    <validation-provider
                      vid="caller_identification_type_id"
                      :name="$t('caller identification type')"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-select
                        v-model="details.caller_identification_type_id"
                        :items="callerIdentificationTypes"
                        name="caller_identification_type_id"
                        :label="$t('caller identification type')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                      >
                        <template v-slot:item="{ item, index }">
                          {{ $t(item) }}
                        </template>
                        <template v-slot:selection="{ item, index }">
                          {{ $t(item) }}
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      vid="call_waiting_enabled"
                      :name="$t('call waiting')"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-checkbox
                        v-model="details.call_waiting_enabled"
                        name="call_waiting_enabled"
                        :label="$t('call waiting')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <validation-provider
                      vid="do_not_disturb_active"
                      :name="$t('do not disturb')"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-checkbox
                        v-model="details.do_not_disturb_active"
                        name="do_not_disturb_active"
                        :label="$t('do not disturb')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      vid="reject_restricted_numbers_active"
                      :name="$t('reject restricted numbers')"
                      v-slot="{ errors, valid, dirty, classes }"
                    >
                      <v-checkbox
                        v-model="details.reject_restricted_numbers_active"
                        name="reject_restricted_numbers_active"
                        :label="$t('reject restricted numbers')"
                        :error-messages="errors"
                        :success="dirty && valid"
                        :class="classes"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  @click="saveCommon()"
                  color="primary"
                  :loading="saving"
                  :disabled="invalid || loading || saving"
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="!details.is_fax2_mail_service">
          <v-expansion-panel-header class="text-subtitle-1">
            {{ $t("voice mail settings") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <validation-observer
              ref="obs_voice_mail"
              v-slot="{ errors, invalid, validated }"
            >
              <v-row dense>
                <v-col cols="12">
                  <validation-provider
                    vid="voice_mail_is_enabled"
                    :name="$t('voice mail enabled')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.voice_mail_is_enabled"
                      name="voice_mail_is_enabled"
                      :label="$t('voice mail enabled')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_password"
                    :name="$t('voice mail password')"
                    :rules="
                      (details.voice_mail_is_enabled ? 'required|' : '') +
                      'numeric'
                    "
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.voice_mail_password"
                      name="voice_mail_password"
                      :label="$t('voice mail password')"
                      :hint="$t('voice mail password')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="!details.voice_mail_is_enabled"
                      type="number"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_enable_auto_authentication"
                    :name="$t('voice mail enable auto authentication')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.voice_mail_enable_auto_authentication"
                      name="voice_mail_enable_auto_authentication"
                      :label="$t('voice mail enable auto authentication')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="!details.voice_mail_is_enabled"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_enable_notification"
                    :name="$t('voice mail notification')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.voice_mail_enable_notification"
                      name="voice_mail_enable_notification"
                      :label="$t('voice mail notification')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="!details.voice_mail_is_enabled"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_notification_email"
                    :name="$t('voice mail notification email')"
                    :rules="
                      (details.voice_mail_enable_notification
                        ? 'required|'
                        : '') + 'email'
                    "
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.voice_mail_notification_email"
                      name="voice_mail_notification_email"
                      :label="$t('voice mail notification email')"
                      :hint="$t('voice mail notification email')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !details.voice_mail_is_enabled ||
                        !details.voice_mail_enable_notification
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_do_not_send_attachment"
                    :name="$t('voice mail do not send attachment')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.voice_mail_do_not_send_attachment"
                      name="voice_mail_do_not_send_attachment"
                      :label="$t('voice mail do not send attachment')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="
                        !details.voice_mail_is_enabled ||
                        !details.voice_mail_enable_notification
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_delete_after_notification"
                    :name="$t('voice mail delete after notification')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.voice_mail_delete_after_notification"
                      name="voice_mail_delete_after_notification"
                      :label="$t('voice mail delete after notification')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="
                        !details.voice_mail_is_enabled ||
                        !details.voice_mail_enable_notification
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_skip_message_menu"
                    :name="$t('voice mail skip message menu')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.voice_mail_skip_message_menu"
                      name="voice_mail_skip_message_menu"
                      :label="$t('voice mail skip message menu')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="!details.voice_mail_is_enabled"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_notify_registered_devices_only"
                    :name="$t('voice mail notify registered devices only')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="
                        details.voice_mail_notify_registered_devices_only
                      "
                      name="voice_mail_notify_registered_devices_only"
                      :label="$t('voice mail notify registered devices only')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="!details.voice_mail_is_enabled"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="voice_mail_locale_id"
                    :name="$t('voice mail language')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-select
                      v-model="details.voice_mail_locale_id"
                      :items="locales"
                      item-value="id"
                      item-text="caption"
                      name="voice_mail_locale_id"
                      :label="$t('voice mail language')"
                      :hint="$t('voice mail language')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="!details.voice_mail_is_enabled"
                      :clearable="!isMobile"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  @click="saveVoiceMail()"
                  color="primary"
                  :loading="saving"
                  :disabled="invalid || loading || saving"
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="!details.is_fax2_mail_service">
          <v-expansion-panel-header class="text-subtitle-1">
            {{ $t("forward settings") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <validation-observer
              ref="obs_forward"
              v-slot="{ errors, invalid, validated }"
            >
              <v-row dense>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_permanent_active"
                    :name="$t('call forward permanent active')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_permanent_active"
                      name="call_forward_permanent_active"
                      :label="$t('call forward permanent active')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_permanent_to_mailbox"
                    :name="$t('call forward permanent to mailbox')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_permanent_to_mailbox"
                      name="call_forward_permanent_to_mailbox"
                      :label="$t('call forward permanent to mailbox')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="
                        !details.call_forward_permanent_active ||
                        !details.voice_mail_is_enabled
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_permanent"
                    :name="$t('call forward permanent target')"
                    :rules="
                      details.call_forward_permanent_active &&
                      !details.call_forward_permanent_to_mailbox
                        ? 'required|phone'
                        : ''
                    "
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.call_forward_permanent"
                      name="call_forward_permanent"
                      :label="$t('call forward permanent target')"
                      :hint="$t('call forward permanent target')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !details.call_forward_permanent_active ||
                        details.call_forward_permanent_to_mailbox
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_busy_active"
                    :name="$t('call forward busy active')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_busy_active"
                      name="call_forward_busy_active"
                      :label="$t('call forward busy active')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_busy_to_mailbox"
                    :name="$t('call forward busy to mailbox')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_busy_to_mailbox"
                      name="call_forward_busy_to_mailbox"
                      :label="$t('call forward busy to mailbox')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="
                        !details.call_forward_busy_active ||
                        !details.voice_mail_is_enabled
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_busy"
                    :name="$t('call forward busy target')"
                    :rules="
                      details.call_forward_busy_active &&
                      !details.call_forward_busy_to_mailbox
                        ? 'required|phone'
                        : ''
                    "
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.call_forward_busy"
                      name="call_forward_busy"
                      :label="$t('call forward busy target')"
                      :hint="$t('call forward busy target')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !details.call_forward_busy_active ||
                        details.call_forward_busy_to_mailbox
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_no_reply_active"
                    :name="$t('call forward no reply active')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_no_reply_active"
                      name="call_forward_no_reply_active"
                      :label="$t('call forward no reply active')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_no_reply_to_mailbox"
                    :name="$t('call forward no reply to mailbox')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_no_reply_to_mailbox"
                      name="call_forward_no_reply_to_mailbox"
                      :label="$t('call forward no reply to mailbox')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="
                        !details.call_forward_no_reply_active ||
                        !details.voice_mail_is_enabled
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_no_reply"
                    :name="$t('call forward no reply target')"
                    :rules="
                      details.call_forward_no_reply_active &&
                      !details.call_forward_no_reply_to_mailbox
                        ? 'required|phone'
                        : ''
                    "
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.call_forward_no_reply"
                      name="call_forward_no_reply"
                      :label="$t('call forward no reply target')"
                      :hint="$t('call forward no reply target')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !details.call_forward_no_reply_active ||
                        details.call_forward_no_reply_to_mailbox
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_not_available_active"
                    :name="$t('call forward not available active')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_not_available_active"
                      name="call_forward_not_available_active"
                      :label="$t('call forward not available active')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_not_available_to_mailbox"
                    :name="$t('call forward not available to mailbox')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-checkbox
                      v-model="details.call_forward_not_available_to_mailbox"
                      name="call_forward_not_available_to_mailbox"
                      :label="$t('call forward not available to mailbox')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      :disabled="
                        !details.call_forward_not_available_active ||
                        !details.voice_mail_is_enabled
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider
                    vid="call_forward_not_available"
                    :name="$t('call forward not available target')"
                    :rules="
                      details.call_forward_not_available_active &&
                      !details.call_forward_not_available_to_mailbox
                        ? 'required|phone'
                        : ''
                    "
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.call_forward_not_available"
                      name="call_forward_not_available"
                      :label="$t('call forward not available target')"
                      :hint="$t('call forward not available target')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !details.call_forward_not_available_active ||
                        details.call_forward_not_available_to_mailbox
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  @click="saveForward()"
                  color="primary"
                  :loading="saving"
                  :disabled="invalid || loading || saving"
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="details.voice_mail_is_enabled">
          <v-expansion-panel-header class="text-subtitle-1">
            {{ $t("voice mails") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <phone-voice-mails
              v-if="details.id != null"
              :product-id="productId"
              :phone-base-id="phoneId"
              :phoneNumberId="details.id"
              hide-toolbar
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import PhoneVoiceMails from "@/components/services/phone/PhoneVoiceMails";

export default {
  name: "PhoneNumberDetails",
  mixins: [showErrors, isMobile, formatDateTime],
  components: {
    BooleanValue,
    PhoneVoiceMails,
  },
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    phoneId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    mobileId: {
      type: [Number, String],
      require: false,
      default: null,
    },
    numberId: {
      type: [Number, String],
      require: false,
      default: null,
    },
    number: {
      type: String,
      required: true,
    },
    quotasAndFunctions: {
      type: Object,
      required: false,
      default: null,
    },
    locales: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    settings: 0,
    details: null,
    loading: false,
    saving: false,
    callerIdentificationTypes: ["CBC", "CLIR", "CLIP"],
    registrationModes: ["DIRECT", "MAIN", "VIAMAIN"],
    /**
    mgcpPorts: [
      { value: -1, name: "MGCP port undefined" },
      { value: 1, name: "MGCP port 1" },
      { value: 2, name: "MGCP port 2" },
    ],
    */
  }),
  computed: {
    isMobilePhone() {
      return this.mobileId != null;
    },
    usageUrl() {
      // get url based isMobile
      this.phoneId;
      this.mobileId;
      var url = "services/phone/" + this.productId;
      if (this.isMobilePhone) url += "/mobiles/" + this.mobileId + "/usage";
      else url += "/phones/" + this.phoneId + "/numbers/" + this.numberId;
      return url;
    },
  },
  watch: {
    numberId: {
      immediate: true,
      handler(value) {
        this.getDetails(value);
      },
    },
  },
  methods: {
    getDetails() {
      var that = this;
      this.loading = true;
      this.$http
        .get(this.usageUrl)
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    saveCommon() {
      var that = this;
      this.saving = true;
      var params = {};
      if (this.details.is_fax2_mail_service) {
        params.is_fax2_mail_service = true;
        params.fax2_mail_target_address = this.details.fax2_mail_target_address;
        params.fax2_mail_password = this.details.fax2_mail_password;
      } else {
        params.is_fax2_mail_service = false;
        params.caller_identification_type_id =
          this.details.caller_identification_type_id;
        params.call_waiting_enabled = this.details.call_waiting_enabled;
        params.do_not_disturb_active = this.details.do_not_disturb_active;
        params.reject_restricted_numbers_active =
          this.details.reject_restricted_numbers_active;
      }
      this.$http
        .patch(this.usageUrl, params)
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$refs.obs_common.setErrors(error.data);
          that.showErrors(that.$refs.obs_common.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.saving = false;
        });
    },
    saveVoiceMail() {
      var that = this;
      this.saving = true;
      var params = {};
      if (this.details.voice_mail_is_enabled) {
        params.voice_mail_is_enabled = true;
        params.voice_mail_password = this.details.voice_mail_password;
        params.voice_mail_enable_auto_authentication =
          this.details.voice_mail_enable_auto_authentication;
        if (this.details.voice_mail_enable_notification) {
          params.voice_mail_enable_notification = true;
          params.voice_mail_notification_email =
            this.details.voice_mail_notification_email;
          params.voice_mail_do_not_send_attachment =
            this.details.voice_mail_do_not_send_attachment;
          params.voice_mail_delete_after_notification =
            this.details.voice_mail_delete_after_notification;
        } else params.voice_mail_enable_notification = false;
        params.voice_mail_skip_message_menu =
          this.details.voice_mail_skip_message_menu;
        params.voice_mail_notify_registered_devices_only =
          this.details.voice_mail_notify_registered_devices_only;
        params.voice_mail_locale_id = this.details.voice_mail_locale_id || 0;
      } else params.voice_mail_is_enabled = false;
      this.$http
        .patch(this.usageUrl, params)
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$refs.obs_voice_mail.setErrors(error.data);
          that.showErrors(that.$refs.obs_voice_mail.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.saving = false;
        });
    },
    saveForward() {
      var that = this;
      this.saving = true;
      var params = {
        call_forward_permanent_active:
          this.details.call_forward_permanent_active,
        call_forward_permanent: this.details.call_forward_permanent,
        call_forward_permanent_to_mailbox:
          this.details.call_forward_permanent_to_mailbox,
        call_forward_busy_active: this.details.call_forward_busy_active,
        call_forward_busy: this.details.call_forward_busy,
        call_forward_no_reply_active: this.details.call_forward_no_reply_active,
        call_forward_no_reply: this.details.call_forward_no_reply,
        call_forward_no_reply_to_mailbox:
          this.details.call_forward_no_reply_to_mailbox,
        call_forward_not_available_active:
          this.details.call_forward_not_available_active,
        call_forward_not_available: this.details.call_forward_not_available,
        call_forward_not_available_to_mailbox:
          this.details.call_forward_not_available_to_mailbox,
        call_forward_rejected_active: this.details.call_forward_rejected_active,
        call_forward_rejected: this.details.call_forward_rejected,
        call_forward_rejected_to_mailbox:
          this.details.call_forward_rejected_to_mailbox,
      };
      this.$http
        .patch(this.usageUrl, params)
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$refs.obs_forward.setErrors(error.data);
          that.showErrors(that.$refs.obs_forward.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.saving = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "phone number": "Phone number",
    "valid from": "Valid from",
    "valid to": "valid to",
    "setup date": "Setup date",
    "provision status": "Provision status",
    "has planned mac address change": "Has planned MAC address change",
    "is main number": "Is main number",
    "is temporary": "Is temporary",
    "save": "Save",
    "common settings": "Common settings",
    "fax to mail settings": "Fax to mail settings",
    "fax to mail": "Fax to mail",
    "number is fax to mail service": "Number is fax to mail service",
    "fax to mail target address": "Fax to mail target address",
    "fax to mail password": "Fax to mail password",
    "caller identification type": "Caller identification type",
    "CLIP": "Always show",
    "CLIR": "Never show",
    "CBC": "Call by call",
    "balanced routing": "Balanced routing",
    "do not disturb": "Do not disturb",
    "reject restricted numbers": "Reject restricted numbers",
    "call waiting": "Call waiting",
    "call holding": "Call holding",
    "is queue": "Is queue",
    "queue length": "Queue length",
    "voice mail settings": "Voice mail settings",
    "voice mail enabled": "enabled",
    "voice mail notification": "Email Notification",
    "voice mail notification email": "Notification email address",
    "voice mail password": "Password (PIN)",
    "voice mail do not send attachment": "Do not send attachment",
    "voice mail delete after notification": "Delete after notification",
    "voice mail enable auto authentication": "Enable auto authentication",
    "voice mail skip message menu": "Skip message menu",
    "voice mail notify registered devices only": "Notify registered devices only",
    "voice mail language": "Voice mail language",
    "forward settings": "Forward settings",
    "call forward permanent active": "Permanent",
    "call forward permanent to mailbox": "To mailbox",
    "call forward permanent target": "Target number",
    "call forward busy active": "Busy",
    "call forward busy to mailbox": "To mailbox",
    "call forward busy target": "Target number",
    "call forward no reply active": "No reply",
    "call forward no reply to mailbox": "To mailbox",
    "call forward no reply target": "Target number",
    "call forward not available active": "Not available",
    "call forward not available to mailbox": "To mailbox",
    "call forward not available target": "Target number",
    "voice mails": "Voice Mails"
  },
  "de": {
    "phone number": "Telefonnummer",
    "valid from": "Gültig von",
    "valid to": "Gültig bis",
    "setup date": "Einrichtungs Datum",
    "provision status": "Bereitstellungsstatus",
    "has planned mac address change": "Hat geplannte MAC Adress Änderung",
    "is main number": "Ist Hauptnummer",
    "is temporary": "Ist temporär",
    "save": "Speichern",
    "common settings": "Allgemeine Einstellungen",
    "fax to mail settings": "Fax nach Email Einstellungen",
    "fax to mail": "Fax to Mail",
    "number is fax to mail service": "Nummer ist Fax nach Email Dienst",
    "fax to mail target address": "Fax nach Email Ziel Adresse",
    "fax to mail password": "Fax nach Email Passwort",
    "caller identification type": "Rufnummernanzeige",
    "CLIP": "Immer anzeigen",
    "CLIR": "Nie anzeigen",
    "CBC": "Call By Call",
    "balanced routing": "Balanced Routing",
    "do not disturb": "Nicht stören",
    "reject restricted numbers": "Anonyme Anrufer abweisen",
    "call waiting": "Anklopfen",
    "call holding": "Gespräch halten",
    "is queue": "Ist Queue",
    "queue length": "Queue Länge",
    "voice mail settings": "VoiceMail Einstellungen",
    "voice mail enabled": "aktivieren",
    "voice mail notification": "Benachrichtigung per Email",
    "voice mail notification email": "Benachrichtigungs Email Adresse",
    "voice mail password": "Passwort (PIN)",
    "voice mail do not send attachment": "Nachricht nicht als Anhang senden",
    "voice mail delete after notification": "Nachricht nach Versand löschen",
    "voice mail enable auto authentication": "Abfrage vom eigenem Telefon ohne PIN",
    "voice mail skip message menu": "VoiceMail Menu überspringen",
    "voice mail notify registered devices only": "Benachrichtige nur registrierte Geräte",
    "voice mail language": "VoiceMail Sprache",
    "forward settings": "Einstellungen zur Rufumleitung",
    "call forward permanent active": "Permanent",
    "call forward permanent to mailbox": "Auf Mailbox",
    "call forward permanent target": "Zielnummer",
    "call forward busy active": "Wenn besetzt",
    "call forward busy to mailbox": "Auf Mailbox",
    "call forward busy target": "Zielnummer",
    "call forward no reply active": "Wenn keine Antwort (nach 20s)",
    "call forward no reply to mailbox": "Auf Mailbox",
    "call forward no reply target": "Zielnummer",
    "call forward not available active": "Wenn nicht erreichbar",
    "call forward not available to mailbox": "Auf Mailbox",
    "call forward not available target": "Zielnummer",
    "voice mails": "Sprachnachrichten"
  }
}
</i18n>
