<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title v-if="details != null">
        {{ details.product_name }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="showChangeDialog = true"
            :disabled="loading || showChangeDialog || !allowChangeDialog"
            icon
          >
            <v-icon>mdi-cellphone-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("change subscription") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: productId },
              })
            "
            :disabled="loading"
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open product") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-progress-linear
      :active="loading || loadingActivationCode"
      indeterminate
    />

    <v-dialog v-model="showChangeDialog" width="80%" scrollable persistent>
      <mobile-phone-change
        v-if="details != null"
        :product-id="productId"
        :subscription="subscription"
        :catalog-item="catalogItem"
        :mobile="details"
        :secondSim="secondSim"
        :thirdSim="thirdSim"
        @cancel="showChangeDialog = false"
        @ordered="showChangeDialog = false"
      />
    </v-dialog>

    <v-card-text>
      <v-simple-table class="mb-4">
        <template v-slot:default>
          <tbody>
            <tr>
              <th>{{ $t("subscription status") }}</th>
              <td>{{ $t(subscription.status_id) }}</td>
            </tr>
            <tr v-if="subscription.date_start">
              <th>{{ $t("start date") }}</th>
              <td>{{ formatDateTime(subscription.date_start) }}</td>
            </tr>
            <tr v-if="subscription.delivery_date">
              <th>{{ $t("delivery date") }}</th>
              <td>{{ formatDateTime(subscription.delivery_date) }}</td>
            </tr>
            <tr v-if="subscription.cancellation_date">
              <th>{{ $t("cancellation date") }}</th>
              <td>{{ formatDateTime(subscription.cancellation_date) }}</td>
            </tr>
            <tr v-if="subscription.termination_request_until_date">
              <th>{{ $t("termination request request until date") }}</th>
              <td>
                {{
                  formatDateTime(subscription.termination_request_until_date)
                }}
              </td>
            </tr>
            <tr v-if="details && details.location_info">
              <th>{{ $t("location and network") }}</th>
              <td>
                {{ details.location_info.country_code }}
              </td>
            </tr>
            <tr v-if="details && details.location_info">
              <th>{{ $t("mobile network") }}</th>
              <td>
                {{ details.location_info.network_brand_name }}
              </td>
            </tr>
            <tr v-if="details && details.features">
              <th>{{ $t("is prepaid") }}</th>
              <td>
                <boolean-value :value="details.features.is_prepaid" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card v-if="showTransferStatus" outlined class="mb-4">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>
            {{ $t("transfer status") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr
                  v-if="
                    details &&
                    details.transfer_status &&
                    details.transfer_status.activation_date
                  "
                >
                  <th>{{ $t("transfer activation date") }}</th>
                  <td>
                    {{
                      formatDateTime(details.transfer_status.activation_date)
                    }}
                  </td>
                </tr>
                <tr v-if="details && details.transfer_status">
                  <th>{{ $t("transfer work order reference") }}</th>
                  <td>
                    #{{ details.transfer_status.onp_work_order_reference }}
                  </td>
                </tr>
                <tr v-if="details && details.transfer_status">
                  <th>{{ $t("transfer ONP status") }}</th>
                  <td>
                    {{ details.transfer_status.onp_status_id }}
                  </td>
                </tr>
                <tr v-if="details && details.transfer_status">
                  <th>{{ $t("transfer ONP send status") }}</th>
                  <td>
                    {{ details.transfer_status.onp_send_status_id }}
                  </td>
                </tr>
                <tr v-if="details && details.transfer_status">
                  <th>{{ $t("port status") }}</th>
                  <td>
                    {{ details.transfer_status.port_status_id }}
                  </td>
                </tr>
                <tr v-if="details && details.transfer_status">
                  <th>{{ $t("provider") }}</th>
                  <td>
                    {{ details.transfer_status.provider_account_id_text }}
                  </td>
                </tr>
                <tr
                  v-if="
                    details &&
                    details.transfer_status &&
                    details.transfer_status.reject_reason_id_text
                  "
                >
                  <th>{{ $t("reject reason") }}</th>
                  <td>
                    {{ details.transfer_status.reject_reason_id_text }}
                  </td>
                </tr>
                <tr
                  v-if="
                    details &&
                    details.transfer_status &&
                    details.transfer_status.reject_reason2_id_text
                  "
                >
                  <th>{{ $t("reject reason 2") }}</th>
                  <td>
                    {{ details.transfer_status.reject_reason2_id_text }}
                  </td>
                </tr>
                <tr
                  v-if="
                    details &&
                    details.transfer_status &&
                    details.transfer_status.reject_reason3_id_text
                  "
                >
                  <th>{{ $t("reject reason 3") }}</th>
                  <td>
                    {{ details.transfer_status.reject_reason3_id_text }}
                  </td>
                </tr>
                <tr
                  v-if="
                    details &&
                    details.transfer_status &&
                    details.transfer_status.reject_reason_remarks
                  "
                >
                  <th>{{ $t("reject remarks") }}</th>
                  <td>
                    {{ details.transfer_status.reject_reason_remarks }}
                  </td>
                </tr>
                <tr
                  v-if="
                    details &&
                    details.transfer_status &&
                    details.transfer_status.remarks
                  "
                >
                  <th>{{ $t("remarks") }}</th>
                  <td>
                    {{ details.transfer_status.remarks }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-card v-if="details != null" outlined>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>
            {{ $t("SIM card") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col
              cols="3"
              v-if="
                details.is_e_sim &&
                activationCode &&
                showEsimActivationCode[details.iccid]
              "
            >
              <figure class="ma-2">
                <v-img
                  :src="activationCode.qr_code_url"
                  :alt="$t('eSIM activation QR code')"
                />
                <v-layout justify-center>
                  <figcaption v-html="$t('eSIM activation QR code')" />
                </v-layout>
              </figure>
            </v-col>
            <v-col
              :cols="
                details.is_e_sim &&
                activationCode &&
                showEsimActivationCode[details.iccid]
                  ? 9
                  : 12
              "
            >
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>{{ $t("phone number") }}</th>
                      <td>{{ details.phone_number }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("provision status") }}</th>
                      <td>{{ $t(details.provision_status) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("is provisioned") }}</th>
                      <td>
                        <boolean-value
                          :value="details.is_provisioned"
                          colored
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("identity verification confirmed") }}</th>
                      <td>
                        <boolean-value
                          :value="details.identity_verification_confirmed"
                          colored
                        />
                      </td>
                    </tr>
                    <tr v-if="!details.identity_verification_confirmed">
                      <th>{{ $t("identity verification URL") }}</th>
                      <td v-if="details.identity_verification_url">
                        <v-btn
                          :href="details.identity_verification_url"
                          target="_blank"
                          outlined
                          small
                        >
                          {{ details.identity_verification_url }}
                          <v-icon right> mdi-open-in-new </v-icon>
                        </v-btn>
                      </td>
                      <td v-else>
                        {{ $t("not available") }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("is eSIM") }}</th>
                      <td>
                        <boolean-value :value="details.is_e_sim" />
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("is data SIM") }}</th>
                      <td>
                        <boolean-value :value="details.is_data_sim" />
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("ICCID") }}</th>
                      <td>{{ details.iccid }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("IMSI") }}</th>
                      <td>{{ details.imsi }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("PIN (initial)") }}</th>
                      <td>{{ details.pin }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("PUK") }}</th>
                      <td>{{ details.puk }}</td>
                    </tr>
                    <tr v-if="details.is_e_sim && activationCode">
                      <th>{{ $t("eSIM activation code") }}</th>
                      <td>
                        <span class="mr-4">
                          {{ activationCode.activation_code }}
                        </span>
                        <v-tooltip
                          top
                          v-if="!showEsimActivationCode[details.iccid]"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                showEsimActivationCode[details.iccid] = true;
                                showEsimActivationCode = {
                                  ...showEsimActivationCode,
                                };
                              "
                              color="primary"
                              fab
                              x-small
                            >
                              <v-icon>mdi-qrcode</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("show activation QR code") }}</span>
                        </v-tooltip>
                        <v-tooltip
                          top
                          v-if="showEsimActivationCode[details.iccid]"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                delete showEsimActivationCode[details.iccid];
                                showEsimActivationCode = {
                                  ...showEsimActivationCode,
                                };
                              "
                              color="primary"
                              fab
                              x-small
                            >
                              <v-icon>mdi-qrcode-remove</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("hide activation QR code") }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("first name") }}</th>
                      <td>{{ details.first_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("last name") }}</th>
                      <td>{{ details.last_name }}</td>
                    </tr>

                    <tr>
                      <th>{{ $t("setup date") }}</th>
                      <td>
                        {{
                          details.setup_date != null
                            ? formatDateTime(details.setup_date)
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr v-if="details.cancel_date != null">
                      <th>{{ $t("cancel date") }}</th>
                      <td>
                        {{ formatDateTime(details.cancel_date) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <validation-observer
        ref="obs"
        v-slot="{ errors, invalid }"
        v-if="forwardSettings != null"
      >
        <v-card class="mt-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title v-if="details != null">
              {{ $t("forward settings") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <validation-provider
                  vid="call_forward_status"
                  :name="$t('call forward status')"
                  rules=""
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="forwardSettings.call_forward_status"
                    :items="callForwardStatuses"
                    name="call_forward_status"
                    :label="$t('call forward status')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :disabled="loadingForwardSettings"
                    single-line
                  >
                    <template v-slot:item="{ item }">
                      {{ $t(item.text) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ $t(item.text) }}
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  vid="forward_to_mailbox"
                  :name="$t('forward to mailbox')"
                  rules=""
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-switch
                    v-model="forwardSettings.forward_to_mailbox"
                    :label="$t('forward to mailbox')"
                    name="forward_to_mailbox"
                    :error-messages="errors"
                    :class="classes"
                    :disabled="
                      forwardSettings == null ||
                      forwardSettings.call_forward_status == 'Disabled'
                    "
                    single-line
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  vid="target"
                  :name="$t('target number')"
                  :rules="
                    forwardSettings != null &&
                    forwardSettings.call_forward_status != 'Disabled' &&
                    !forwardSettings.forward_to_mailbox
                      ? 'required|phone'
                      : ''
                  "
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="forwardSettings.target"
                    :label="$t('target number')"
                    name="target"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :disabled="
                      forwardSettings == null ||
                      forwardSettings.forward_to_mailbox
                    "
                    single-line
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mb-2 mx-2">
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="
                invalid || loadingForwardSettings || $store.getters.isReadOnly
              "
              :loading="loadingForwardSettings"
              @click="updateForwardSettings"
              text
            >
              {{ $t("save settings") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>

      <validation-observer
        ref="obs"
        v-slot="{ errors, invalid }"
        v-if="barringSettings != null"
      >
        <v-card class="mt-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title v-if="details != null">
              {{ $t("barring settings") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="bar_premium_sms"
                  :name="$t('barring premium SMS')"
                  rules=""
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-checkbox
                    v-model="barringSettings.bar_premium_sms"
                    name="bar_premium_sms"
                    :label="$t('barring premium SMS')"
                    :error-messages="errors"
                    :class="classes"
                    :disabled="loadingBarringSettings"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="bar_premium_calls"
                  :name="$t('barring premium calls')"
                  rules=""
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-checkbox
                    v-model="barringSettings.bar_premium_calls"
                    name="bar_premium_calls"
                    :label="$t('barring premium calls')"
                    :error-messages="errors"
                    :class="classes"
                    :disabled="loadingBarringSettings"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mb-2 mx-2">
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="
                invalid || loadingBarringSettings || $store.getters.isReadOnly
              "
              :loading="loadingBarringSettings"
              @click="updateBarringSettings"
              text
            >
              {{ $t("save settings") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>

      <v-card v-if="additionalSimCards.length > 0" class="mt-4" outlined>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title v-if="details != null">
            {{ $t("additional SIM cards") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headersAdditionalSimCards"
            :items="additionalSimCards"
            :mobile-breakpoint="mbreakpoint"
            show-expand
            single-expand
            sort-by="package_name"
            :hide-default-footer="additionalSimCards.length <= 15"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [15, 50],
            }"
          >
            <template v-slot:item.is_e_sim="{ item }">
              <boolean-value :value="item.is_e_sim" />
            </template>

            <template v-slot:item.is_data_sim="{ item }">
              <boolean-value :value="item.is_data_sim" />
            </template>

            <template v-slot:item.provision_status="{ item }">
              {{ $t(item.provision_status) }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row align="center" justify="center">
                  <v-col
                    cols="3"
                    v-if="
                      item.is_e_sim &&
                      item.esim_activation_code &&
                      showEsimActivationCode[item.iccid]
                    "
                  >
                    <figure class="ma-2">
                      <v-img
                        :src="item.esim_activation_code.qr_code_url"
                        :alt="$t('eSIM activation QR code')"
                      />
                      <v-layout justify-center>
                        <figcaption v-html="$t('eSIM activation QR code')" />
                      </v-layout>
                    </figure>
                  </v-col>
                  <v-col
                    :cols="
                      item.is_e_sim &&
                      item.esim_activation_code &&
                      showEsimActivationCode[item.iccid]
                        ? 9
                        : 12
                    "
                  >
                    <v-simple-table class="ma-4">
                      <template v-slot:default>
                        <tbody>
                          <!--- 
                          <tr>
                            <th>{{ $t("phone number") }}</th>
                            <td>{{ item.number }}</td>
                          </tr>
                          -->
                          <tr>
                            <th>{{ $t("provision status") }}</th>
                            <td>{{ $t(item.provision_status) }}</td>
                          </tr>
                          <tr>
                            <th>{{ $t("is eSIM") }}</th>
                            <td>
                              <boolean-value :value="item.is_e_sim" />
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t("is data SIM") }}</th>
                            <td>
                              <boolean-value :value="item.is_data_sim" />
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t("ICCID") }}</th>
                            <td>{{ item.iccid }}</td>
                          </tr>
                          <tr>
                            <th>{{ $t("IMSI") }}</th>
                            <td>{{ item.imsi }}</td>
                          </tr>
                          <tr>
                            <th>{{ $t("PIN (initial)") }}</th>
                            <td>{{ item.pin }}</td>
                          </tr>
                          <tr>
                            <th>{{ $t("PUK") }}</th>
                            <td>{{ item.puk }}</td>
                          </tr>
                          <tr>
                            <th>{{ $t("PUK2") }}</th>
                            <td>{{ item.puk2 }}</td>
                          </tr>
                          <tr v-if="item.is_e_sim && item.esim_activation_code">
                            <th>{{ $t("eSIM activation code") }}</th>
                            <td>
                              <span class="mr-4">
                                {{ item.esim_activation_code.activation_code }}
                              </span>
                              <v-tooltip
                                top
                                v-if="!showEsimActivationCode[item.iccid]"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="
                                      showEsimActivationCode[item.iccid] = true;
                                      showEsimActivationCode = {
                                        ...showEsimActivationCode,
                                      };
                                    "
                                    color="primary"
                                    fab
                                    x-small
                                  >
                                    <v-icon>mdi-qrcode</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("show activation QR code") }}</span>
                              </v-tooltip>
                              <v-tooltip
                                top
                                v-if="showEsimActivationCode[item.iccid]"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="
                                      delete showEsimActivationCode[item.iccid];
                                      showEsimActivationCode = {
                                        ...showEsimActivationCode,
                                      };
                                    "
                                    color="primary"
                                    fab
                                    x-small
                                  >
                                    <v-icon>mdi-qrcode-remove</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("hide activation QR code") }}</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <mobile-phone-consumption
        v-if="showPackages"
        :consumptions="details.packages_consumptions"
        class="mt-4"
      />

      <mobile-phone-packages-add
        v-if="showPackages"
        :packages="packagesAvailableInCurrentZone"
        :loading="orderingPackage"
        :title="$t('packages for the currently determined zone')"
        in-current-zone
        @orderPackage="orderPackage"
        class="mt-4"
      />

      <mobile-phone-packages-add
        v-if="showPackages"
        :packages="packagesAvailableNotInCurrentZone"
        :loading="orderingPackage"
        :title="$t('packages for other zones')"
        @orderPackage="orderPackage"
        class="mt-4"
      />

      <phone-voice-mails
        v-if="details != null"
        :product-id="productId"
        :mobile-id="mobileId"
        class="mt-4"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDuration from "@/utils/mixins/formatDuration";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import MobilePhoneConsumption from "@/components/services/phone/MobilePhoneConsumption";
import MobilePhonePackagesAdd from "@/components/services/phone/MobilePhonePackagesAdd";
import PhoneNumberDetails from "@/components/services/phone/PhoneNumberDetails";
import MobilePhoneChange from "@/components/services/phone//MobilePhoneChange";
import PhoneVoiceMails from "@/components/services/phone/PhoneVoiceMails";

export default {
  name: "MobilePhoneDetails",
  components: {
    BooleanValue,
    MobilePhoneConsumption,
    MobilePhonePackagesAdd,
    PhoneNumberDetails,
    MobilePhoneChange,
    PhoneVoiceMails,
  },
  mixins: [showErrors, formatDateTime, formatNumber, formatDuration, isMobile],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    productId: {
      type: [Number, String],
      required: true,
    },
    mobileId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    showChangeDialog: false,
    loading: false,
    loadingActivationCode: false,
    loadingForwardSettings: false,
    loadingBarringSettings: false,
    loadingProductCatalog: false,
    productCatalog: [],
    details: null,
    activationCode: null,
    forwardSettings: null,
    barringSettings: null,
    locales: [],
    loadingLocales: false,
    notFound: false,
    orderingPackage: false,
    additionalSimCards: [],
    loadingadditionalSimCards: false,
    callForwardStatuses: [
      { text: "disabled", value: "Disabled" },
      { text: "busy", value: "Busy" },
      { text: "unconditional", value: "Unconditional" },
    ],
    showEsimActivationCode: {},
  }),
  computed: {
    showTransferStatus() {
      var limit = this.$moment().subtract(30, "days");
      return (
        this.details &&
        this.details.transfer_status &&
        this.details.transfer_status.activation_date &&
        this.$moment(this.details.transfer_status.activation_date).isAfter(
          limit
        )
      );
    },
    allowChangeDialog() {
      return (
        this.details &&
        this.subscription &&
        this.subscription.status_id == "ACTIVATED" &&
        this.catalogItem != null &&
        (this.details.transfer_status == null ||
          this.details.transfer_status.onp_status_id == "OK") &&
        this.details.is_provisioned &&
        this.details.identity_verification_confirmed
      );
    },
    secondSim() {
      if (this.additionalSimCards.length > 0) return this.additionalSimCards[0];
    },
    thirdSim() {
      if (this.additionalSimCards.length > 1) return this.additionalSimCards[1];
    },
    headersAdditionalSimCards() {
      return [
        /**
        {
          text: this.$i18n.t("phone number"),
          value: "number",
        },
        */
        {
          text: this.$i18n.t("provision status"),
          value: "provision_status",
        },
        {
          text: this.$i18n.t("is eSIM"),
          value: "is_e_sim",
        },
        {
          text: this.$i18n.t("is data SIM"),
          value: "is_data_sim",
        },
        {
          text: this.$i18n.t("ICCID"),
          value: "iccid",
        },
        {
          text: this.$i18n.t("IMSI"),
          value: "imsi",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headersConsumption() {
      return [
        {
          text: this.$i18n.t("active"),
          value: "active",
        },
        {
          text: this.$i18n.t("name"),
          value: "name",
        },
        {
          text: this.$i18n.t("voice_used"),
          value: "voice_used",
        },
        {
          text: this.$i18n.t("sms_used"),
          value: "sms_used",
        },
        {
          text: this.$i18n.t("data_used"),
          value: "data_used",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    showPackages() {
      return this.details && this.details.provision_status == "ACTIVATED";
    },
    packagesAvailable() {
      return this.showPackages
        ? this.details.packages_available.filter(function (pkg) {
            if (pkg.gfo_variant != null) return pkg;
          })
        : [];
    },
    packagesAvailableInCurrentZone() {
      return this.packagesAvailable.filter(function (pkg) {
        if (pkg.is_in_current_zone) return pkg;
      });
    },
    packagesAvailableNotInCurrentZone() {
      return this.packagesAvailable.filter(function (pkg) {
        if (!pkg.is_in_current_zone) return pkg;
      });
    },
    catalogItem() {
      this.productCatalog;
      this.subscription;
      for (let i = 0; i < this.productCatalog.length; i++) {
        if (
          this.subscription &&
          this.productCatalog[i].base_product_id ==
            this.subscription.base_product_id
        )
          return this.productCatalog[i];
      }
    },
  },
  watch: {
    mobileId: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getDetails();
          // this.getLocales();
          this.getForwardSettings();
          this.getBarringSettings();
          this.getAdditionalSimCards();
          this.getProductCatalog();
        } else {
          this.details = null;
          this.activationCode = null;
          this.locales = [];
          this.forwardSettings = null;
          this.barringSettings = null;
          this.additionalSimCards = [];
        }
      },
    },
  },
  methods: {
    getDetails() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/phone/" + this.productId + "/mobiles/" + this.mobileId, {
          disableDefaultErrorHandler: true,
        })
        .then((response) => {
          that.details = response.data;
          if (that.details.is_e_sim) that.getActivationCode();
        })
        .catch((error) => {
          if (error.status == 404)
            that.$store.commit("setSystemError", {
              msg: that.$t("notFoundMsg"),
              title: that.$t("notFoundTitle"),
            });
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getActivationCode() {
      var that = this;
      this.loadingActivationCode = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/esim-activation-code",
          {
            disableDefaultErrorHandler: true,
          }
        )
        .then((response) => {
          that.activationCode = response.data;
        })
        .catch((error) => {
          if (error.status != 404)
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingActivationCode = false;
        });
    },
    getAdditionalSimCards() {
      var that = this;
      this.loadingadditionalSimCards = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/additional-sim-cards"
        )
        .then((response) => {
          that.additionalSimCards = response.data;
        })
        .catch((error) => {
          if (error.status != 404)
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingadditionalSimCards = false;
        });
    },
    getForwardSettings() {
      var that = this;
      this.loadingForwardSettings = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/forward-settings",
          {
            disableDefaultErrorHandler: true,
          }
        )
        .then((response) => {
          that.forwardSettings = response.data;
        })
        .catch((error) => {
          if (error.status != 404)
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingForwardSettings = false;
        });
    },
    updateForwardSettings() {
      var that = this;
      this.loadingForwardSettings = true;
      this.$http
        .put(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/forward-settings",
          {
            call_forward_status: this.forwardSettings.call_forward_status,
            forward_to_mailbox: this.forwardSettings.forward_to_mailbox,
            target: this.forwardSettings.target || null,
          }
        )
        .then((response) => {
          that.forwardSettings = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingForwardSettings = false;
        });
    },
    getBarringSettings() {
      var that = this;
      this.loadingBarringSettings = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/barrings",
          {
            disableDefaultErrorHandler: true,
          }
        )
        .then((response) => {
          that.barringSettings = response.data;
        })
        .catch((error) => {
          if (error.status != 404)
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingBarringSettings = false;
        });
    },
    updateBarringSettings() {
      var that = this;
      this.loadingBarringSettings = true;
      this.$http
        .put(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/barrings",
          {
            bar_premium_sms: this.barringSettings.bar_premium_sms,
            bar_premium_calls: this.barringSettings.bar_premium_calls,
          }
        )
        .then((response) => {
          that.barringSettings = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingBarringSettings = false;
        });
    },
    getLocales() {
      var that = this;
      this.loadingLocales = true;
      this.$http
        .get("services/phone/locales")
        .then((response) => {
          that.locales = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingLocales = false;
        });
    },
    getProductCatalog() {
      var that = this;
      this.loadingProductCatalog = true;
      this.$http
        .get("services/phone/product-catalog", { params: { base: "mobile" } })
        .then((response) => {
          that.productCatalog = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingProductCatalog = false;
        });
    },
    orderPackage(pkg) {
      var that = this;
      this.orderingPackage = true;
      this.$http
        .post(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobileId +
            "/add-packages",
          [{ package_id: pkg.package_id }]
        )
        .then((response) => {
          this.details = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.orderingPackage = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "show activation QR code": "show activation QR code",
    "hide activation QR code": "hide activation QR code",
    "subscription status": "Subscription status",
    "start date": "Start date",
    "delivery date": "Delivery date",
    "cancellation date": "Cancellation date",
    "termination request request until date": "Termination request request until date",
    "open product": "open product",
    "SIM card": "SIM card",
    "phone number": "Phone number",
    "eSIM activation QR code": "eSIM activation QR code",
    "eSIM activation code": "eSIM activation code",
    "provision status": "Provision status",
    "is eSIM": "eSIM",
    "is data SIM": "Data SIM",
    "is provisioned": "Is provisioned",
    "first name": "First name",
    "last name": "Last name",
    "setup date": "Setup date",
    "cancel date": "Cancel date",
    "ICCID": "ICCID",
    "IMSI": "IMSI",
    "PIN (initial)": "PIN (initial)",
    "PUK": "PUK",
    "PUK2": "PUK2",
    "language": "Language",
    "consumption": "Consumption",
    "active": "Active",
    "name": "Name",
    "voice_used": "Voice",
    "sms_used": "SMS",
    "data_used": "Data",
    "of": "of",
    "type": "Type",
    "initial only": "Initial only",
    "recurring": "Recurring",
    "phone number settings": "Phone number settings",
    "notFoundMsg": "Mobile service not found in backend, please contact support or wait until setup is done!",
    "notFoundTitle": "Mobile service not found",
    "additional SIM cards": "Additional SIM cards",
    "call forward status": "Call forward status",
    "disabled": "disabled",
    "busy": "busy or not available",
    "unconditional": "unconditional",
    "forward settings": "Forward settings",
    "forward to mailbox": "forward to mailbox",
    "target number": "target number",
    "save settings": "Save settings",
    "barring settings": "Barring settings",
    "barring premium SMS": "barring premium SMS",
    "barring premium calls": "barring premium calls",
    "change subscription": "change subscription",
    "not available": "not available",
    "identity verification confirmed": "Identity verification confirmed",
    "identity verification URL": "Identity verification URL",
    "transfer status": "Transfer status",
    "transfer activation date": "Activation date",
    "transfer work order reference": "Work order reference",
    "transfer ONP status": "ONP status",
    "transfer ONP send status": "ONP send status",
    "port status": "Port status",
    "provider": "provider",
    "reject reason": "Reject reason",
    "reject reason 2": "Reject reason 2",
    "reject reason 3": "Reject reason 3",
    "reject remarks": "Reject remarks",
    "ONP": "Operator Number Portability",
    "remarks": "Remarks",
    "NEW": "New",
    "ACTIVATED": "Activated",
    "ACTIVATIONPENDING": "Activation pending",
    "DEACTIVATED": "Deactivated",
    "DEACTIVATIONPENDING": "Deactivation pending",
    "CANCELLED": "Cancelled",
    "CANCELLATIONPENDING": "Canceltion pending",
    "CLOSINGBILLPENDING": "Closing bill pending",
    "SETUPPENDING": "Setup pending",
    "CHANGEPENDING": "Change pending",
    "REMOVALPENDING": "Removal pending",
    "packages for the currently determined zone": "Packages for the currently determined zone",
    "packages for other zones": "Packages for other zones",
    "location and network": "Location and network",
    "mobile network": "Mobile network",
    "is prepaid": "Is prepaid"
  },
  "de": {
    "show activation QR code": "zeige Aktivierungs QR Code",
    "hide activation QR code": "blende Aktivierungs QR Code aus",
    "subscription status": "Abonnement Status",
    "start date": "Start Datum",
    "delivery date": "Lieferdatum",
    "cancellation date": "Kündigungsdatum",
    "termination request request until date": "Kündigung bis Datum",
    "open product": "öffne Produkt",
    "SIM card": "SIM Karte",
    "phone number": "Rufnummer",
    "eSIM activation QR code": "eSIM Aktivierungs QR Code",
    "eSIM activation code": "eSIM Aktivierungs Code",
    "provision status": "Bereitstellungsstatus",
    "is eSIM": "eSIM",
    "is data SIM": "Daten SIM",
    "is provisioned": "Ist bereitgestellt",
    "first name": "Vorname",
    "last name": "Nachname",
    "setup date": "Einrichtungs Datum",
    "cancel date": "Kündigungs Datum",
    "ICCID": "ICCID",
    "IMSI": "IMSI",
    "PIN (initial)": "PIN (initial)",
    "PUK": "PUK",
    "PUK2": "PUK2",
    "language": "Sprache",
    "consumption": "Verbrauch",
    "active": "Aktiv",
    "name": "Name",
    "voice_used": "Sprache",
    "sms_used": "SMS",
    "data_used": "Daten",
    "of": "von",
    "type": "Typ",
    "initial only": "Nur intitial",
    "recurring": "Wiederkehrend",
    "phone number settings": "Rufnummern Einstellungen",
    "notFoundMsg": "Mobil Dienst im Backend nicht gefunden, kontaktieren Sie den Support order warten Sie bis die Einrichtung abgeschlossen ist!",
    "notFoundTitle": "Mobil Dienst nicht gefunden",
    "additional SIM cards": "Zusätzliche SIM Karten",
    "call forward status": "Anrufweiterleitung Status",
    "disabled": "abgeschaltet",
    "busy": "besetzt oder nicht erreichbar",
    "unconditional": "immer",
    "forward settings": "Weiterleitungs Einstellungen",
    "forward to mailbox": "Weiterleitung auf VoiceMail",
    "target number": "Zielnummer",
    "save settings": "Einstellungen speichern",
    "barring settings": "Sperreinstellungen",
    "barring premium SMS": "SMS zu Mehrwertnummern sperren",
    "barring premium calls": "Telefonate zu Mehrwertnummern sperren",
    "change subscription": "Abonnement ändern",
    "not available": "nicht verfügbar",
    "identity verification confirmed": "Identitätsprüfung bestätigt",
    "identity verification URL": "Identitätsprüfung URL",
    "transfer status": "Transfer Status",
    "transfer activation date": "Aktivierungs Datum",
    "transfer work order reference": "Auftragsreferenz",
    "transfer ONP status": "ONP Status",
    "transfer ONP send status": "ONP Sendestatus",
    "port status": "Portierungs Status",
    "provider": "Anbieter",
    "reject reason": "Ablehngrund",
    "reject reason 2": "Ablehngrund 2",
    "reject reason 3": "Ablehngrund 3",
    "reject remarks": "Ablehnanmerkung",
    "ONP": "Operator Number Portability",
    "remarks": "Anmerkung",
    "NEW": "Neu",
    "ACTIVATED": "Aktiviert",
    "ACTIVATIONPENDING": "Aktivierung in Bearbeitung",
    "DEACTIVATED": "Deaktiviert",
    "DEACTIVATIONPENDING": "Deaktivierung in Bearbeitung",
    "CANCELLED": "Gekündigt",
    "CANCELLATIONPENDING": "Kündigung in Bearbeitung",
    "CLOSINGBILLPENDING": "Rechnungsabschluss in Bearbeitung",
    "SETUPPENDING": "Einrichtung in Bearbeitung",
    "CHANGEPENDING": "Änderung in Bearbeitung",
    "REMOVALPENDING": "Löschung in Bearbeitung",
    "packages for the currently determined zone": "Pakete für die aktuell ermittelte Zone",
    "packages for other zones": "Pakete für andere Zonen",
    "location and network": "Standort und Netzwerk",
    "mobile network": "Mobilfunknetz",
    "is prepaid": "Ist Prepaid"
  }
}
</i18n>
