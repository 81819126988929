<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat>
      <v-toolbar-title>{{ $t("delivery receipt") }}</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text class="pa-4">
      <wysiwyg-editor v-model="content" :renderLinks="false" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('receiptCanceled')" text>
        {{ $t("cancel") }}
      </v-btn>
      <v-btn @click="$emit('receiptReset')" text>
        {{ $t("reset") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="$emit('receiptSaved', content.trim())"
        text
      >
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import wysiwygEditor from "@/components/basics/wysiwygEditor";

export default {
  name: "HardwareShopDeliveryReceipt",
  components: {
    wysiwygEditor,
  },
  props: {
    receipt: {
      required: true,
      validator: (prop) => typeof prop == "string" || prop == null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: this.receipt || "",
    };
  },
  watch: {
    receipt() {
      this.content = this.receipt || "";
    },
  },
  mounted() {
    this.content = this.receipt || "";
  },
};
</script>

<i18n>
{
  "en": {
      "delivery receipt": "Delivery receipt",
      "cancel": "Cancel",
      "save": "Save",
      "reset": "Reset"
  },
  "de": {
      "delivery receipt": "Lieferschein",
      "cancel": "Abbrechen",
      "save": "Speichern",
      "reset": "Zurücksetzen"
  }
}
</i18n>
