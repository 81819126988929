var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.areaCodes,"label":_vm.$t('area code'),"clearable":!_vm.isMobile,"loading":_vm.loadingAreaCodes,"no-data-text":_vm.$t('no area codes found'),"hide-no-data":!_vm.search_area_code,"search-input":_vm.search_area_code,"disabled":_vm.disabled,"type":"number"},on:{"update:searchInput":function($event){_vm.search_area_code=$event},"update:search-input":function($event){_vm.search_area_code=$event},"click:clear":function($event){_vm.search_area_code = '';
        _vm.pattern = '';
        _vm.getAreaCodes();
        _vm.getNumbers();},"input":function($event){_vm.pattern = '';
        _vm.getNumbers();}},model:{value:(_vm.areaCode),callback:function ($$v) {_vm.areaCode=$$v},expression:"areaCode"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.numbers,"item-text":"number","item-value":"id","item-disabled":_vm.isDisabled,"label":_vm.label,"hint":_vm.hint,"clearable":!_vm.isMobile,"loading":_vm.loadingNumbers,"no-data-text":_vm.$t('no numbers found'),"hide-no-data":!_vm.pattern,"search-input":_vm.pattern,"disabled":_vm.disabled,"return-object":"","type":"number","open-on-clear":""},on:{"update:searchInput":function($event){_vm.pattern=$event},"update:search-input":[function($event){_vm.pattern=$event},function($event){return _vm.getNumbers()}],"input":function($event){_vm.$emit('select', _vm.selection);
        _vm.selection = null;
        _vm.pattern = null;}},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }