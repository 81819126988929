<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense v-if="!hideToolbar">
      <v-toolbar-title>
        {{ $t("voice mails") }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="loading"
            :loading="loading"
            @click="getVoiceMails"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="call_date"
        sort-desc
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50, 100, 500, 1000],
        }"
        @click:row="selectItem"
        :no-data-text="$t('no voice mails available')"
        single-select
        :item-class="itemClass"
      >
        <template v-slot:top>
          <audio-player
            v-if="streamUrl"
            :file="streamUrl"
            :mime-type="mimeType"
            :auto-play="autoPlay"
            color="primary"
            downloadable
            flat
          />
        </template>
        <template v-slot:item.call_date="{ item }">
          <div class="monospace">
            {{ item.call_date ? formatDateTime(item.call_date) : "-" }}
          </div>
        </template>
        <template v-slot:item.start="{ item }">
          <div class="monospace">
            {{ item.start ? formatDateTime(item.start) : "-" }}
          </div>
        </template>
        <template v-slot:item.duration="{ item }">
          <div class="monospace">
            {{ item.duration != null ? formatDuration(item.duration) : "-" }}
          </div>
        </template>

        <template v-slot:item.called_station="{ item }">
          <div class="monospace">{{ item.caller_id || "-" }}</div>
        </template>

        <template v-slot:item.calling_station="{ item }">
          <div class="monospace">{{ item.caller_name || "-" }}</div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDuration from "@/utils/mixins/formatDuration";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import AudioPlayer from "@/components/basics/AudioPlayer";

export default {
  name: "PhoneVoiceMails",
  components: {
    AudioPlayer,
  },
  mixins: [showErrors, formatDateTime, formatDuration, isMobile],
  props: {
    productId: {
      type: [String, Number],
      require: true,
    },
    mobileId: {
      type: [String, Number],
      require: false,
      default: null,
    },
    phoneBaseId: {
      type: [String, Number],
      require: false,
      default: null,
    },
    phoneNumberId: {
      type: [String, Number],
      require: false,
      default: null,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    loadingDownload: false,
    item: null,
    items: [],
    autoPlay: false,
  }),
  computed: {
    mimeType() {
      return this.mobileId != null ? "audio/mp3" : "audio/wav";
    },
    headers() {
      return this.mobileId != null
        ? [
            {
              text: this.$i18n.t("call date"),
              value: "call_date",
            },
            {
              text: this.$i18n.t("caller ID"),
              value: "caller_id",
            },
            {
              text: this.$i18n.t("caller name"),
              value: "caller_name",
            },
            {
              text: this.$i18n.t("duration"),
              value: "duration",
            },
          ]
        : [
            {
              text: this.$i18n.t("call date"),
              value: "start",
            },
            {
              text: this.$i18n.t("caller ID"),
              value: "phone_number",
            },
            {
              text: this.$i18n.t("caller name"),
              value: "name",
            },
            {
              text: this.$i18n.t("duration"),
              value: "duration",
            },
          ];
    },
    voiceMailsUrl() {
      return (
        "services/phone/" +
        this.productId +
        (this.mobileId != null
          ? "/mobiles/" + this.mobileId + "/voice-mails"
          : "/phones/" +
            this.phoneBaseId +
            "/numbers/" +
            this.phoneNumberId +
            "/voice-mails")
      );
    },
    itemUrl() {
      if (this.item != null) return this.voiceMailsUrl + "/" + this.item.id;
    },
    streamUrl() {
      if (this.item != null)
        return (
          this.$config.backendUrl +
          this.itemUrl +
          "?format=" +
          (this.mobileId != null ? "mp3" : "wav")
        );
    },
  },
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        this.getVoiceMails();
      },
    },
    mobileId(value) {
      this.getVoiceMails();
    },
    phoneBaseId(value) {
      this.getVoiceMails();
    },
    phoneNumberId(value) {
      this.getVoiceMails();
    },
  },
  methods: {
    selectItem(item, row) {
      this.item = item;
      // row.select(true);
      this.autoPlay = true;
    },
    itemClass(item) {
      return item && this.item && item.id == this.item.id
        ? "primary--text"
        : "";
    },
    getVoiceMails() {
      if (!this.loading) {
        this.autoPlay = false;
        var that = this;
        this.loading = true;
        this.$http
          .get(this.voiceMailsUrl)
          .then((response) => {
            that.items = response.data;
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loading = false;
            that.item = that.items.length > 0 ? that.items[0] : null;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "voice mails": "Voice Mails",
    "call date": "Call Date",
    "caller ID": "Caller ID",
    "caller name": "Caller Name",
    "duration": "Duration",
    "reload": "Reload",
    "no voice mails available": "no voice mails available"
  },
  "de": {
    "voice mails": "Sprachnachrichten",
    "call date": "Gesprächszeit",
    "caller ID": "Anrufer",
    "caller name": "Anrufer Name",
    "duration": "Länge",
    "reload": "aktualisieren",
    "no voice mails available": "keine Sprachnachrichten vorhanden"
  }
}
</i18n>

<style scoped></style>
