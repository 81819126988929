import store from "@/store";

export default {
  methods: {
    formatTime: function (timeString, zone='') {
      return new Date('1970-01-01T' + timeString + zone).toLocaleTimeString(store.state.locale == 'en' ? 'en-GB' : store.state.locale, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      });
    }
  }
  // Usage:
  // import formatTime from "@/utils/mixins"
  // export default {
  //   mixins: [formatTime],

  // im Template: {{ formatTime(date_time_string) }}
};
