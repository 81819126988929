<template>
  <v-row>
    <v-col cols="4">
      <v-autocomplete
        v-model="areaCode"
        :items="areaCodes"
        :label="$t('area code')"
        :clearable="!isMobile"
        :loading="loadingAreaCodes"
        :no-data-text="$t('no area codes found')"
        :hide-no-data="!search_area_code"
        :search-input.sync="search_area_code"
        :disabled="disabled"
        @click:clear="
          search_area_code = '';
          pattern = '';
          getAreaCodes();
          getNumbers();
        "
        @input="
          pattern = '';
          getNumbers();
        "
        type="number"
      />
    </v-col>
    <v-col cols="8">
      <v-autocomplete
        v-model="selection"
        :items="numbers"
        item-text="number"
        item-value="id"
        :item-disabled="isDisabled"
        :label="label"
        :hint="hint"
        :clearable="!isMobile"
        :loading="loadingNumbers"
        :no-data-text="$t('no numbers found')"
        :hide-no-data="!pattern"
        :search-input.sync="pattern"
        :disabled="disabled"
        return-object
        @update:search-input="getNumbers()"
        @input="
          $emit('select', selection);
          selection = null;
          pattern = null;
        "
        type="number"
        open-on-clear
      />
    </v-col>
  </v-row>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";

export default {
  name: "PhoneSelectNumber",
  mixins: [showErrors, isMobile, formatPhoneNumber],
  props: {
    baseProductId: {
      type: [String, Number],
      require: true,
    },
    label: {
      type: String,
      default() {
        return this.$i18n.t("available phone numbers");
      },
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedNumbers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loadingNumbers: false,
      selection: null,
      pattern: null,
      numbers: [],
      loadingAreaCodes: false,
      areaCodes: [],
      areaCode: null,
      search_area_code: null,
    };
  },
  computed: {
    loading() {
      return this.loadingNumbers || this.loadingAreaCodes;
    },
  },
  watch: {
    baseProductId: {
      immediate: true,
      handler() {
        this.getNumbers();
        this.getAreaCodes();
      },
    },
  },
  methods: {
    isDisabled(number) {
      return (
        number &&
        (number.provision_status != "AVAILABLE" ||
          this.selectedNumbers.includes(number.number))
      );
    },
    getNumbers() {
      if (this.baseProductId) {
        var that = this;
        this.loadingNumbers = true;
        this.$http
          .get("services/phone/number-lookup", {
            params: {
              base_product_id: this.baseProductId,
              pattern: this.pattern || null,
              area_code: this.areaCode ? this.areaCode : null,
            },
          })
          .then((response) => {
            that.numbers = response.data.map(function (num) {
              num.formated = that.formatPhoneNumber(
                num.full_number,
                "CH",
                true
              );
              return num;
            });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loadingNumbers = false;
          });
      }
    },
    getAreaCodes() {
      var that = this;
      this.loadingAreaCodes = true;
      this.$http
        .get("services/phone/area-codes", {
          params: { base_product_id: this.baseProductId },
        })
        .then((response) => {
          that.areaCodes = response.data.map((code) => code.area_code);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingAreaCodes = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "no numbers found": "no numbers found",
    "available phone numbers": "available phone numbers",
    "no area codes found": "no area codes found",
    "area code": "area code"
  },
  "de": {
    "no numbers found": "keine Nummern gefunden",
    "available phone numbers": "verfügbare Rufnummern",
    "no area codes found": "keine Vorwahlen gefunden",
    "area code": "Vorwahl"
  }
}
</i18n>
