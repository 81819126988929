import store from "@/store";

const zeroPad = (num, places) => String(num).padStart(places, '0')

export default {
  methods: {
    formatDuration: function (seconds, human=false) {
      var days     = Math.floor(seconds / (24*60*60));
      seconds -= days    * (24*60*60);
      var hours    = Math.floor(seconds / (60*60));
      seconds -= hours   * (60*60);
      var minutes  = Math.floor(seconds / (60));
      seconds -= minutes * (60);
      
      if (human)
        return (
          (0 < days ? (days + " day, ") : "") +
          (0 < hours ? (hours + " hour, ") : "") +
          (0 < minutes ? (minutes + " min, ") : "") +
          seconds + " sec"
        );
      else
        return (
          zeroPad(days * 24 + hours, 2) + ':' +
          zeroPad(minutes, 2) + ':' +
          zeroPad(seconds, 2)
        );
    }
  }
};
