import store from "@/store";

export default {
  methods: {
    formatNumber: function (number, unit = null, currencyDisplay = 'symbol', useGrouping = true, minimumFractionDigits = 2, maximumFractionDigits = 2) {
      var result = number.toLocaleString(store.state.locale == 'en' ? 'en-GB' : store.state.locale, {
        currencyDisplay: currencyDisplay,
        useGrouping: useGrouping,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
      });
      if (unit != null)
        result += ' ' + unit;
      return result;
    }
  }
};
