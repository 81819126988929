/*
TODO: add duration support !!!
*/
import axios from "axios";

export default {
  data() {
    return {
      teldasNoneWorkingDays: {},
    };
  },
  methods: {
    isWeekend(date) {
      return this.$moment(date).isoWeekday() > 5;
    },
    addWorkingDays(date, days) {
      let i = 0;
      let nextDate = date || new Date();
      while (i < days) {
        nextDate = this.$moment(nextDate).add(1, "days");
        if (!this.isWeekend(nextDate) && !this.isTeldasNoneWorkingDay(nextDate)) i = i + 1;
      }
      return nextDate.toDate();
    },
    subtractWorkingDays(date, days) {
      let i = 0;
      let prevDate = date || new Date();
      while (i < days) {
        prevDate = this.$moment(prevDate).subtract(1, "days");
        if (!this.isWeekend(prevDate) && !this.isTeldasNoneWorkingDay(prevDate)) i = i + 1;
      }
      return prevDate.toDate();
    },
    isTeldasNoneWorkingDay(s) {
      var date = this.$moment(s);
      var yearStr = this.$moment(date).format('YYYY');
      var dateStr = this.$moment(date).format('YYYY-MM-DD');
      return (
        this.teldasNoneWorkingDays[yearStr] != null &&
        this.teldasNoneWorkingDays[yearStr][dateStr] != null
      );
    },
    async loadTeldasNoneWorkingDays() {
      var that = this;
      var year = new Date().getFullYear();
      const storeDays = function (data, yearStr) {
        that.teldasNoneWorkingDays[yearStr] = {};
        data.forEach((day) => {
          that.teldasNoneWorkingDays[yearStr][day.date] = day;
        });
      };
      await axios.all([
        this.$http
          .get("services/phone/teldas-none-working-days", {
            params: {
              year: year,
            },
          })
          .then((response) => {
            storeDays(response.data, year.toString());
          }),
        this.$http
          .get("services/phone/teldas-none-working-days", {
            params: {
              year: year + 1,
            },
          })
          .then((response) => {
            storeDays(response.data, (year + 1).toString());
          }),
      ]);
    },
  },
  created() {
    this.loadTeldasNoneWorkingDays();
  },
};
